import React, {useState} from 'react';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';
import {navigate} from 'gatsby';
import {Helmet} from 'react-helmet';
import {orderBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import Layout from '../components/layout';
import {useCommercialPage} from '../hooks/queries';
import {displayRichText} from '../utils';
import {
  Carousel,
  Button,
  IconCard,
  MediaTile,
  MediaTileMobile,
  VideoLightBox,
} from '../components/common';
import RouterPaths from '../values/RouterPaths';
import RecipeCard from '../components/common/RecipeCard/RecipeCard';
import '../styles/pages/index.scss';

const IndexPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const {commercial, featuresAndBenefits, recipes, versatileKitchen} =
    useCommercialPage();
  const {
    title,
    heading,
    headingBody,
    headingImage,
    videoThumbnail,
    videoTitle,
    videoUrl,
    sectionOneBody,
    benefits,
    benefitIcons,
    sectionTwoHeading,
    ctaHeading,
    ctaBody,
    sectionThreeHeading,
    sectionFourHeading,
    // eslint-disable-next-line no-unused-vars
    featureFootnote,
  } = commercial;
  const {nodes: featureData} = featuresAndBenefits;
  const {nodes: recipeData} = recipes;
  const {nodes: kitchenData} = versatileKitchen;
  const headingBackground = getImage(headingImage.localFile);
  const {t} = useTranslation();
  const handleGetInContact = () => {
    navigate(RouterPaths.CONTACT);
  };
  const handleRequestDemo = () => {
    navigate(RouterPaths.CONTACT, {state: {inquiry: 'commercial'}});
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="commercial-page flex flex-col justify-center w-full">
        <BgImage
          image={headingBackground}
          className="w-full flex items-center justify-center">
          <div className="flex w-full overlay absolute z-0 left-0 bottom-0 top-0" />
          <div className="commercial-page__hero flex flex-col justify-center text-center z-10">
            <h1 className="text-5xl desktop:text-6xl w-full font-semibold">
              {heading}
            </h1>
            <div className="text-xl tablet:text-2xl font-semibold">
              {displayRichText(headingBody.raw)}
            </div>
          </div>
        </BgImage>
        <div className="commercial-page__section-one flex flex-col align-center justify-center w-full items-center">
          <div className="commercial-page__section-one__card text-2xl font-semibold w-full flex justify-center">
            <div className="commercial-page__section-one__card__content text-left">
              {displayRichText(sectionOneBody.raw)}
            </div>
          </div>
          <div className="commercial-page__section-one__content w-full flex flex-col items-center">
            {/* <BgImage
              image={backgroundImage}
              className="commercial-page__section-one__benefits__noise w-full"
            /> */}
            <div className="commercial-page__section-one__content__video">
              <div
                onClick={openModal}
                onKeyDown={openModal}
                role="button"
                tabIndex={0}>
                <GatsbyImage
                  image={getImage(videoThumbnail.localFile)}
                  alt={videoThumbnail.description}
                />
              </div>
              <VideoLightBox
                isOpen={modalIsOpen}
                videoUrl={videoUrl}
                videoTitle={videoTitle}
                onClose={closeModal}
              />
            </div>
            <div className="commercial-page__section-one__content__benefits flex w-full justify-center">
              {benefits.map((benefit, index) => {
                const icon = benefitIcons[index];
                return (
                  <IconCard
                    key={benefit}
                    description={benefit}
                    icon={icon}
                    richText={false}
                    classes="commercial-page__section-one__content__benefits__card"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="commercial-page__section-two flex justify-center">
          <div className="commercial-page__section-two__content text-center flex flex-col items-center">
            <div className="commercial-page__section-two__content__header desktop:text-5xl tablet:text-4xl text-3xl text-center tablet:text-center">
              {displayRichText(sectionTwoHeading.raw)}
            </div>
            <div className="commercial-page__section-two__content__cards--desktop hidden tablet:flex flex-col">
              {orderBy(kitchenData, ['order'], ['asc']).map(
                ({title, media, body}, index) => (
                  <MediaTile
                    title={title}
                    image={getImage(media.localFile)}
                    altText={media.description}
                    description={body.raw}
                    type={index + 1}
                    textClasses={`z-${index + 1}0`}
                  />
                ),
              )}
            </div>
            <div className="commercial-page__section-two__content__cards--mobile flex tablet:hidden flex-col">
              {orderBy(kitchenData, ['order'], ['asc']).map(
                ({title, media, body}, index) => (
                  <MediaTileMobile
                    title={title}
                    image={getImage(media.localFile)}
                    altText={media.description}
                    description={body.raw}
                    type={index}
                  />
                ),
              )}
            </div>
          </div>
        </div>
        <div className="commercial-page__cta flex justify-center">
          <div className="commercial-page__cta__content text-center">
            <span className="desktop:text-4xl text-3xl font-semibold">
              {displayRichText(ctaHeading.raw)}
            </span>
            <span className="text-xl desktop:text-2xl font-semibold">
              {displayRichText(ctaBody.raw)}
            </span>
            <Button
              label={t('commercial:buttons.demo')}
              classes="button--light"
              handleClick={handleGetInContact}
            />
          </div>
        </div>
        <div className="commercial-page__section-three flex flex-col items-center justify-center">
          <div className="commercial-page__section-three__heading desktop:text-4xl text-3xl font-semibold text-left tablet:text-center">
            {displayRichText(sectionThreeHeading.raw)}
          </div>
          <Carousel
            items={orderBy(recipeData, ['order'], ['asc']).map(
              ({title, image}) => (
                <RecipeCard title={title} image={image} />
              ),
            )}
          />
        </div>
        <div className="commercial-page__section-four flex justify-center">
          <div className="commercial-page__section-four__content text-center flex flex-col desktop:items-center">
            <span className="commercial-page__section-four__content__heading desktop:text-5xl tablet:text-4xl text-3xl font-semibold flex justify-center">
              {displayRichText(sectionFourHeading.raw)}
            </span>
            <div className="commercial-page__section-four__content__features flex flex-row flex-wrap justify-center">
              {orderBy(featureData, ['order'], ['asc']).map(
                ({title, body, icon}) => (
                  <IconCard
                    key={title}
                    title={title}
                    description={body.raw}
                    icon={icon}
                    classes="commercial-page__section-four__content__features__card w-full"
                  />
                ),
              )}
            </div>
            <div className="commercial-page__section-four__content__cta">
              <Button
                label={t('commercial:buttons.demo')}
                classes="button--light"
                handleClick={handleRequestDemo}
              />
            </div>
            {/* <div className="commercial-page__section-four__content__footnote">
              <div className="commercial-page__section-four__content__footnote__container text-left">
                <span className="text-base">
                  {displayRichText(featureFootnote.raw)}
                </span>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
